import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import Link from "../components/link";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import Navigation from "../components/navigation";
import SVGRing from "../components/SVG/ring";
import SVGVeryThinRing from "../components/SVG/very-thin-ring";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import { v4 as uuidv4 } from "uuid";

const PricesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "prices" }) {
        pricesFields {
          pricesBanner {
            pricesBannerTitle
          }
          pricesCta {
            pricesCtaButton {
              title
              target
              url
            }
            pricesCtaHeading
          }
          pricesSection {
            pricesItems {
              pricesItemHeading
              pricesItemPrice
              pricesItemText
              pricesItemButton {
                title
                target
                url
              }
            }
          }
        }
        seoFields {
          metaTitle
          metaDescription
          opengraphTitle
          opengraphDescription
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, pricesFields },
  } = data;

  const { pricesBanner, pricesCta, pricesSection } = pricesFields;
  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Prices",
        item: {
          url: `${siteUrl}/prices`,
          id: `${siteUrl}/prices`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <LocalBusinessSchema />
      <GatsbySeo
        title={seoFields?.metaTitle}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <section
        className="position-relative"
        style={{
          background:
            "transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
          opacity: 1,
        }}
      >
        <Navigation background="none" />
        <SVGVeryThinRing
          style={{ left: "-17%" }}
          className="position-absolute top--70  d-none d-xl-block "
        />
        <SVGRing
          style={{ width: "25rem", height: "25rem" }}
          className="position-absolute top--60 end-10 d-none d-xl-block"
        />
        <Container>
          {" "}
          <Row>
            <Col>
              <h1 className="text-primary mt-8 mb-5 text-center display-4 jost-bold">
                {pricesBanner?.pricesBannerTitle || "Prices"}
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="justify-content-center ">
            <Col xs={10} lg={12} xl={9}>
              {pricesSection.pricesItems &&
                pricesSection?.pricesItems.length > 0 && (
                  <Row className="justify-content-center align-items-center ">
                    {pricesSection?.pricesItems.map((item, i) => (
                      <Col
                        style={{
                          boxShadow: "0px 3px 6px #00000029",
                          opacity: 1,
                        }}
                        className={
                          i % 2 === 0
                            ? "mx-3 mb-5 mb-lg-0 text-center px-0"
                            : "text-center mb-5 mb-lg-0 price-card mx-3 px-0"
                            
                        }
                        lg={3}
                        key={uuidv4()}
                      >
                        <div
                          className={
                            i % 2 === 0
                              ? "bg-secondary py-4 my-0"
                              : "bg-primary py-4 my-0"
                          }
                        >
                          <p className="jost-medium text-uppercase text-white py-2 my-0 fs-6">
                            {item.pricesItemHeading}
                          </p>
                        </div>
                        <div className={`content-wrapper py-2 ${i % 2 === 0 ? "bg-priceDark " : ""}`}>
                          <SafeHtmlParser htmlContent={item?.pricesItemPrice} />
                        </div>
                        <div
                          className={`bg-priceLight px-3 ${
                            i % 2 === 0 ? "py-3" : "py-5"
                          }`}
                        >
                          <SafeHtmlParser htmlContent={item?.pricesItemText} />
                        </div>
                        <div className=" py-4 px-4">
                          <Button
                            variant={i % 2 !== 0 ? "primary" : "secondary"}
                            className="cta-btn w-100 w-md-auto w-lg-100 px-4"
                            as={Link}
                            to={item.pricesItemButton?.url}
                          >
                            {item.pricesItemButton?.title}
                          </Button>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
            </Col>
          </Row>
        </Container>
      </section>

      {pricesCta && !checkPropertiesForNull(pricesCta, ["pricesCtaButton"]) && (
        <section className="my-6 text-center">
          <Container>
            <Row>
              <Col>
                <SafeHtmlParser htmlContent={pricesCta?.pricesCtaHeading} />
                {pricesCta?.pricesCtaButton &&
                  pricesCta?.pricesCtaButton.url && (
                    <Button
                      className="px-4 cta-btn w-100 w-md-auto"
                      as={Link}
                      to={pricesCta?.pricesCtaButton.url}
                    >
                      {pricesCta?.pricesCtaButton.title}
                    </Button>
                  )}
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </Layout>
  );
};

export default PricesPage;
